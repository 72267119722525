import { Timestamp } from '@engineering11/types'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { ICnectUser } from '../../model/interfaces'
import { UserActions, UserActionTypes } from './user.actions'

export interface State extends EntityState<ICnectUser> {
  loaded: boolean
  loading: boolean
  error: [] | null
  currentUser: Timestamp<ICnectUser> | null
  token: string | null
}

export const adapter: EntityAdapter<ICnectUser> = createEntityAdapter<ICnectUser>()

export const initialState: State = adapter.getInitialState({
  loaded: false,
  loading: false,
  error: null,
  currentUser: null,
  token: null,
})

export function reducer(state = initialState, action: UserActions): State {
  switch (action.type) {
    case UserActionTypes.onInitUser:
    case UserActionTypes.logOut:
    case UserActionTypes.logIn:
      return {
        ...state,
        loading: true,
      }
    case UserActionTypes.logInSuccess:
      return {
        ...state,
        currentUser: action.payload?.currentUser,
      }
    case UserActionTypes.onUserDocumentChanged:
      return {
        ...state,
        currentUser: action.payload,
      }
    case UserActionTypes.onIdTokenChanged:
      return {
        ...state,
        token: action.payload,
      }
    case UserActionTypes.logOutSuccess:
      return {
        ...state,
        currentUser: null,
      }
    default:
      return state
  }
}

export const userEntitySelectors = adapter.getSelectors()
