import { Component, EventEmitter, Input, Output } from '@angular/core'
import { groupByKey } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { RequestedUpdateFields } from '../../../model/candidate-application.model'
import { UserMode } from '../../../model/component-modes.enum'
import { MatchType } from '../../../model/enum'
import { ICertification, IMatchedCertification } from '../../../model/interfaces'

const FilterTypes = Object.freeze({ ...MatchType, NoMatch: 'NoMatch' as const })
type FilterKeys = keyof typeof FilterTypes
const employerFilterTypeCopy: Record<FilterKeys, string> = {
  [FilterTypes.Exact]: 'Exact matches to the certifications listed in the job post',
  [FilterTypes.NoMatch]: 'Certifications listed in the job post that the candidate does not have ',
  [FilterTypes.NotListed]: 'Certifications the candidate has that were not listed in the job post',
  [FilterTypes.Partial]: 'Partial matches to the certifications listed in the job post',
}

const candidateFilterTypeCopy: Record<FilterKeys, string> = {
  [FilterTypes.Exact]: employerFilterTypeCopy.Exact,
  [FilterTypes.Partial]: employerFilterTypeCopy.Partial,
  [FilterTypes.NoMatch]: 'Certifications you have that were not listed in the job post',
  [FilterTypes.NotListed]: 'Certifications listed in the job post that you do not have',
}

@Component({
  selector: 'certifications-result',
  template: `<div class="skill-certifications">
      <!-- Tabs -->
      <div *ngIf="!pdfMode">
        <div *ngIf="matchedCerts.length !== 0; else emptyState">
          <e11-tabs
            tabTitleOverrides="e11-font-normal e11-text-base"
            [selectedTabId]="filterCurrent"
            (selectedTabChanged)="changedTab($event)"
            [tabsClassOverrides]="'e11-mb-2'"
          >
            <ng-container *ngFor="let filterType of filterTypes">
              <e11-tab
                *ngIf="!_showOnlyMissingCertifications || (_showOnlyMissingCertifications && filterType === FilterTypes.NoMatch)"
                [title]="translationFilterTypes[filterType] | translate"
                [tabId]="filterType"
              >
                <ng-container *ngIf="filterCurrent === filterType">
                  <p *ngIf="userMode === UserMode.Employer">
                    {{ employerFilterTypeCopy[filterType] | translate }}
                  </p>
                  <p *ngIf="userMode === UserMode.Candidate">
                    {{ candidateFilterTypeCopy[filterType] | translate }}
                  </p>
                  <ng-container *ngIf="groupedCertifications[filterCurrent] && groupedCertifications[filterCurrent].length > 0; else noCertificate">
                    <manage-certifications
                      [readOnly]="readOnly || !(userMode === UserMode.Candidate && filterCurrent === FilterTypes.NoMatch)"
                      [markAsMissingSkills]="readOnly || (userMode === UserMode.Candidate && filterCurrent === FilterTypes.NoMatch)"
                      [currentCertifications]="groupedCertifications[filterCurrent]"
                      [loading]="false"
                      (newCertifications)="newCertifications.emit($event)"
                    ></manage-certifications>
                  </ng-container>
                  <ng-template #noCertificate>
                    <h4 class="e11-font-bold e11-text-center e11-my-8">{{ 'No matching certifications' | translate }}</h4>
                  </ng-template>
                </ng-container>
              </e11-tab>
            </ng-container>
          </e11-tabs>
        </div>
      </div>

      <!-- EXPANDED FOR PDF -->
      <div *ngIf="pdfMode">
        <ng-container *ngFor="let filterType of filterTypes">
          <ng-container *ngIf="groupedCertifications[filterType].length > 0">
            <section
              class="e11-mb-8"
              *ngIf="!_showOnlyMissingCertifications || (_showOnlyMissingCertifications && filterType === FilterTypes.NoMatch)"
            >
              <p class="e11-mb-1 e11-text-md e11-text-skin-base e11-font-bold e11-linebreak ng-star-inserted">
                <span class="e11-border-b e11-border-skin-grey/50">{{ translationFilterTypes[filterType] | translate }}</span>
              </p>
              <p>
                {{ employerFilterTypeCopy[filterType] | translate }}
              </p>
              <ng-container *ngIf="groupedCertifications[filterType] && groupedCertifications[filterType].length > 0; else noCertificate">
                <manage-certifications
                  [readOnly]="readOnly || !(userMode === UserMode.Candidate && filterType === FilterTypes.NoMatch)"
                  [markAsMissingSkills]="readOnly || (userMode === UserMode.Candidate && filterType === FilterTypes.NoMatch)"
                  [currentCertifications]="groupedCertifications[filterType]"
                  [loading]="false"
                  (newCertifications)="newCertifications.emit($event)"
                ></manage-certifications>
              </ng-container>
              <ng-template #noCertificate>
                <h4 class="e11-font-bold e11-text-center e11-my-8">{{ 'No matching certifications' | translate }}</h4>
              </ng-template>
            </section>
          </ng-container>
        </ng-container>
      </div>
    </div>

    <ng-template #emptyState>
      <e11-empty-state
        *ngIf="!certifications && userMode === UserMode.Candidate"
        [icon]="'class'"
        [iconSize]="'md-36'"
        title="{{ 'No Certifications' | translate }}"
        [clickable]="false"
        subtitle="{{ 'You have not added any certifications.' | translate }}"
      >
      </e11-empty-state>
      <e11-empty-state
        *ngIf="!certifications && userMode !== UserMode.Candidate"
        [icon]="'class'"
        [iconSize]="'md-36'"
        title="{{ 'No Certifications' | translate }}"
        [subtitle]="
          openRequest
            ? 'Requested on ' + (requestTimestamp | date : 'MM/dd/yyyy - hh:mm a')
            : ('The candidate did not add Certifications.' | translate)
        "
        [clickable]="false"
        [borderColor]="openRequest ? 'e11-border-skin-secondary' : ''"
        [showButton]="true"
        [buttonValue]="openRequest ? 'Pending Response' : 'Request Certifications'"
        [buttonColor]="
          openRequest
            ? 'e11-border-skin-secondary e11-text-skin-secondary'
            : 'e11-border-skin-primary-accent hover:e11-bg-skin-primary-accent/20 e11-text-skin-primary-accent'
        "
        [buttonIcon]="openRequest ? 'pending' : ''"
        (buttonClicked)="!requestsDisabled && requestModal()"
        [disabled]="!!openRequest"
      >
      </e11-empty-state>
    </ng-template> `,
  styleUrls: ['./certifications.component.scss'],
})
export class CandidateCertificationsResultComponent {
  FilterTypes = FilterTypes
  employerFilterTypeCopy = employerFilterTypeCopy
  candidateFilterTypeCopy = candidateFilterTypeCopy
  UserMode = UserMode
  filterTypes = Object.values(FilterTypes)
  filterCurrent: FilterKeys = MatchType.Exact

  translationFilterTypes = {
    Exact: 'Exact',
    Partial: 'Partial',
    NotListed: 'Other',
    NoMatch: 'Missing',
  }

  groupedCertifications: Record<string, ICertification[]> = Object.values(FilterTypes).reduce(
    (prv, nxt) => ({
      ...prv,
      [nxt]: [],
    }),
    {}
  )

  // Needed for empty state
  matchedCerts!: IMatchedCertification[]

  constructor(private logger: E11Logger) {}

  @Output() requestFromComponent = new EventEmitter()
  @Output() newCertifications = new EventEmitter<ICertification[]>()

  @Input() openRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false
  @Input() showRequestButton = true
  @Input() pdfMode: boolean = false
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() requestTimestamp?: string

  @Input() set certifications(matchedCertifications: IMatchedCertification[]) {
    this.matchedCerts = matchedCertifications
    this.groupedCertifications = { ...this.groupedCertifications, ...groupByKey(matchedCertifications, 'matchType') }
  }

  @Input() set missingCertifications(missingCertifications: ICertification[]) {
    this.groupedCertifications = { ...this.groupedCertifications, NoMatch: missingCertifications }
  }

  @Input() readOnly = false

  _showOnlyMissingCertifications = false

  @Input() set showOnlyMissingCertifications(showOnlyMissing: boolean) {
    this._showOnlyMissingCertifications = showOnlyMissing

    if (this._showOnlyMissingCertifications) this.filterCurrent = FilterTypes.NoMatch
  }

  requestModal() {
    this.requestFromComponent.emit('Certifications')
  }

  changedTab(event: any) {
    this.filterCurrent = event.tabId as FilterKeys
  }
}
