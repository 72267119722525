import { Component, OnInit } from '@angular/core'
import { PRIMARY_OUTLET, Router } from '@angular/router'
import { Module, Platform, View } from '@candidate/app/analytics.constants'
import { ToastService } from '@candidate/app/components/toast/toast.service'
import { AppConfigService } from '@engineering11/tenant-bootstrap-web'
import { AnalyticsService } from '@engineering11/web-api-firebase'

@Component({
  templateUrl: './email-verification.component.html',
  styleUrls: ['./email-verification.component.scss'],
})
export class AuthEmailVerificationComponent implements OnInit {
  constructor(
    private analyticsService: AnalyticsService,
    private router: Router,
    private appConfig: AppConfigService,
    private toastService: ToastService
  ) {}
  logoUrl = this.appConfig.config.brand.logoImageDarkUrl
  ngOnInit(): void {
    this.toastService.closeToast()
    const urlTree = this.router.parseUrl(this.router.url)
    const primary = urlTree.root.children[PRIMARY_OUTLET]
    let redirectPath = ''
    if (primary.segments.length > 1) {
      redirectPath = decodeURIComponent(primary.segments[primary.segments.length - 1].path || redirectPath)
    }
  }

  resendEmail(): void {
    this.sendAnalyticsEvent('authentication_resend_email', 'form_submit')
  }

  sendAnalyticsEvent(analyticsEvent: string, userAction: string) {
    this.analyticsService.logEvent(Platform.dev, {
      module: Module.authentication,
      view: View.authenticationEmailVerification,
      analyticsEvent: analyticsEvent,
      userAction: userAction,
    })
  }
}
