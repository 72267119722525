import { Component, EventEmitter, Input, Output } from '@angular/core'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'
import { DegreeLevel } from '../../model/enum'
import { IEducation } from '../../model/interfaces'

@Component({
  selector: 'candidate-education',
  template: `
    <e11-empty-state
      *ngIf="!loading && education.length === 0 && userMode !== userModeEnum.Candidate"
      [icon]="'school'"
      [iconSize]="'md-36'"
      containerClassOverrides="e11-mt-0"
      title="{{ 'No Educational Information' | translate }}"
      [subtitle]="
        openRequest ? 'Requested on ' + (requestTimestamp | date : 'MM/dd/yyyy - hh:mm a') : ('Candidate did not supply any information.' | translate)
      "
      [clickable]="false"
      [borderColor]="openRequest ? 'e11-border-skin-secondary' : ''"
      [showButton]="true"
      [buttonValue]="openRequest ? 'Pending Request' : 'Request Education'"
      [buttonColor]="
        openRequest
          ? 'e11-border-skin-secondary e11-text-skin-secondary'
          : 'e11-border-skin-primary-accent hover:e11-bg-skin-primary-accent/20 e11-text-skin-primary-accent'
      "
      [buttonIcon]="openRequest ? 'pending' : ''"
      (buttonClicked)="!requestsDisabled && requestModal()"
      [disabled]="!!openRequest"
    >
    </e11-empty-state>
  `,
  styleUrls: ['./candidate-education.component.scss'],
})
export class CandidateEducationComponent {
  @Input() education: IEducation[] = []
  @Input() openRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false
  @Input() userMode = UserMode.Candidate
  @Input() loading = false
  @Input() requestTimestamp?: string
  @Output() requestFromComponent = new EventEmitter()

  userModeEnum = UserMode
  degreeLevel = DegreeLevel

  constructor() {}

  requestModal() {
    this.requestFromComponent.emit('Education')
  }

  stateToStatus = new Map<DegreeLevel, string>()
    .set(this.degreeLevel.NotSpecified, myDegreeLevel('Not Specified'))
    .set(this.degreeLevel.HighSchool, myDegreeLevel('High School'))
    .set(this.degreeLevel.VocationalHsDiploma, myDegreeLevel('Vocational High School Diploma'))
    .set(this.degreeLevel.VocationalDegree, myDegreeLevel('Vocational Degree'))
    .set(this.degreeLevel.Associates, myDegreeLevel("Associate's Degree"))
    .set(this.degreeLevel.Bachelors, myDegreeLevel("Bachelor's Degree"))
    .set(this.degreeLevel.Masters, myDegreeLevel("Master's Degree"))
    .set(this.degreeLevel.PhD, myDegreeLevel('Doctorate/PhD'))
}

const myDegreeLevel = (str: string) => `${str}`
