<div class="e11-w-full" *ngIf="!loading && currentCertifications.length === 0 && state !== 'edit'">
  <e11-empty-state
    *ngIf="userMode === userModeEnum.Candidate"
    [icon]="'class'"
    [iconSize]="'md-36'"
    title="{{ 'No Certifications Added' | translate }}"
    [clickable]="false"
    containerClassOverrides="e11-mt-0"
    subtitle="{{ 'Show them what you earned! Add your certifications to your profile.' | translate }}"
  >
  </e11-empty-state>

  <e11-empty-state
    *ngIf="userMode !== userModeEnum.Candidate"
    [icon]="'class'"
    [iconSize]="'md-36'"
    title="{{ 'No Certifications Added' | translate }}"
    [clickable]="false"
    containerClassOverrides="e11-mt-0"
    subtitle="{{ 'Candidate did not supply any certifications' | translate }}"
  >
  </e11-empty-state>

  <div class="e11-flex e11-w-full e11-justify-center e11-items-center e11-mt-3">
    <ng-container *ngTemplateOutlet="addCertButton"></ng-container>
  </div>
</div>

<div>
  <div *ngIf="loading || currentCertifications.length" class="e11-flex e11-flex-wrap e11-gap-2 e11-items-center">
    <div id="certification-tag-{{ i }}" *ngFor="let item of currentCertifications; let i = index">
      <ui-tag-candidate
        [item]="item"
        [type]="'certification'"
        [readOnly]="readOnly"
        [markAsMissingSkills]="markAsMissingSkills"
        (tagAction)="userTagAction($event, currentCertifications, i)"
      ></ui-tag-candidate>
    </div>
    <ng-container *ngIf="loading">
      <ui-tag-candidate *ngFor="let i of 8 | range" [item]="undefined" [type]="'certification'"></ui-tag-candidate>
    </ng-container>
    <ng-container>
      <ng-container *ngTemplateOutlet="addCertButton"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #certificationPopover>
  <div class="e11-px-4 e11-pt-4 e11-pb-0">
    <form class="e11-mb-8" action="" [formGroup]="formAddCert" (ngSubmit)="onSubmitFormAddCert(currentCertifications); closePopover()" novalidate>
      <div class="e11-flex e11-flex-wrap e11-justify-center e11-gap-4">
        <div class="e11-w-full md:e11-w-fit e11-pb-4 md:e11-pb-0">
          <e11-input
            [type]="'text'"
            name="nameCert"
            id="input_nameCert"
            class="input-certification"
            label="{{ 'Name' | translate }}"
            [size]="'sm'"
            borderGlow="primary"
            [parentForm]="formAddCert"
            formControlName="nameCert"
            [required]="true"
            [maxLength]="100"
          >
            <div class="md:e11-hidden">
              <e11-error-messages [controls]="f.nameCert" [errorMessages]="errorMessages"></e11-error-messages>
            </div>
          </e11-input>
        </div>
        <div class="e11-flex e11-items-end">
          <e11-button [value]="ctaTextCertification" [color]="'primary-accent'" [buttonGroup]="true" [type]="'submit'" [size]="'sm'"></e11-button>
          <e11-button value="{{ 'Cancel' | translate }}" color="neutral" [ghost]="true" [size]="'sm'" (click)="closePopover()"> </e11-button>
        </div>
      </div>
      <div class="e11-hidden md:e11-flex e11-w-full e11-relative">
        <e11-error-messages [errorMessages]="errorMessages" [controls]="f.nameCert"></e11-error-messages>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #addCertButton>
  <div id="add-certification-action">
    <div
      *ngIf="!loading && !readOnly && !markAsMissingSkills"
      class="e11-min-h-[3rem] hover:e11-bg-skin-primary-accent/20 hover:e11-text-skin-primary e11-flex e11-text-skin-primary-accent e11-items-center e11-justify-center e11-rounded e11-border e11-border-dashed e11-border-skin-primary-accent hover:e11-border-skin-primary e11-text-center e11-text-sm e11-px-3 e11-cursor-pointer"
      (click)="stateChange(''); openPopover('add-certification-action')"
    >
      <span class="material-icons-outlined e11-mr-1">add_circle</span> {{ 'Add Certification' | translate }}
    </div>
  </div>
</ng-template>
