import { InjectionToken } from '@angular/core'
import { IEnvironment } from './model/environment.model'
import { ErrorHandlingConfig } from './model/error-handling-config-model'
import { BaseParticipantInformation, IConversationTypeConfig } from '@engineering11/messaging-web'

export enum COLLECTIONS {
  CANDIDATE_APPLICATION = 'jobs_candidate_application/',
  CANDIDATE_APPLICATION_STASH = 'candidate_application_stash/',
  CANDIDATE_APPLICATION_STAGE = 'jobs_candidate_application_stage/',
  CANDIDATE_APPLICATION_UPDATE_STAGE = 'jobs_candidate_application_update_stage/',
  CANDIDATE_POOL = 'candidate_pool/',
  JOB_PROFILE = 'jobs_job_profile/',
  JOB_POST_USER_CONFIG = 'jobs_job_post_user_config/',
  JOB_POST = 'jobs_job_post/',
  JOB_POST_APPLICATION = '/jobs_job_application/', // subcollection of job_post
  JOB_APPLICATION_COMMENT = '/jobs_job_application_comment/', // subcollection of job_application
  JOB_APPLICATION_REVIEW = '/jobs_job_application_review/', // subcollection of job_application
  EMPLOYER = 'employer/',
  REPORTED_CONTENT = 'reported_content/',
  VIRTUAL_DIALOGUE_EMPLOYER = 'virtual-dialogue_employer/',
  VIRTUAL_DIALOGUE_CANDIDATE = 'virtual-dialogue_candidate/',
  CANDIDATE_RESUME = 'candidate_resume/',
  CANDIDATE_VIDEO = 'candidate_video/',
  CANDIDATE_JOB_VM = 'candidate_job_vm/',
  JOB_APPLICATION_VIEWS = 'job_application_views/',
  JOB_POST_PROMOTION = 'jobs_job_post_promotion/',
  INTERVIEW_QUESTION = 'interview_question/',
  AUTOMATION_CONFIG = 'jobs_automation_config/',
  CUSTOMER_AUTOMATION_CONFIG = 'jobs_automation_customer_config/',
}

export enum SEARCH_INDICES {
  JOB_POST_APPLICATION = 'job_post_application',
  APPLICATION_JOB_SCORE_ASC = 'application_job_score_asc',
  APPLICATION_DATE_DESC = 'application_date_desc',
  APPLICATION_DATE_ASC = 'application_date_asc',
  APPLICATION_LAST_NAME_DESC = 'application_last_name_desc',
  APPLICATION_LAST_NAME_ASC = 'application_last_name_asc',
  APPLICATION_EXPERIENCE_DESC = 'application_experience_desc',
  APPLICATION_EXPERIENCE_ASC = 'application_experience_asc',

  JOB_PROFILE = 'job_profile',
  JOB_POST = 'job_post',

  JOB_POST_DATE_DESC = 'job_post_date_desc',
  JOB_POST_CITY_DESC = 'job_post_city_desc',
  JOB_POST_LOCATION_DESC = 'job_post_location_desc',
  CANDIDATE_POOL = 'candidate_pool',
  CANDIDATE_POOL_LAST_NAME_ASC = 'candidate_pool_last_name_asc',
  CANDIDATE_POOL_LAST_NAME_DESC = 'candidate_pool_last_name_desc',
}

export const ENVIRONMENT = new InjectionToken<IEnvironment>('environment')
export const ERROR_HANDLING = new InjectionToken<ErrorHandlingConfig>('error_handling')

export const NEW_PROFILE_TITLE = 'New Profile'

export const NOTIFICATION_DROPDOWN_COUNT = 5
export const CONVERSATION_DROPDOWN_COUNT = 5

export const IDENTICON = {
  ROOT_PATH: 'assets/identicon/',
  TAIL: '_identity.svg',
}

// Video
export const MIN_DURATION_VIDEO = 10 // seconds
export const MAX_DURATION_VIDEO = 60 // seconds

export enum ConversationTypes {
  DirectMessage = 'direct-message',
  JobApplication = 'application-conversation',
  JobApplicationDM = 'application-conversation-dm',
  SocialDm = 'social-dm',
  CandidatePoolDM = 'candidate-pool-dm',
}

export enum InputsContext {
  Name = 'name',
  Description = 'description',
  LargeText = 'largeText',
}

export enum Sizes {
  ExtraSmall = 'xs',
  Small = 'sm',
  Medium = 'md',
  Large = 'lg',
  ExtraLarge = 'xl',
}

export const INPUTS_MAXLENGTH_BY_CONTEXT: { [key in InputsContext]: number } = {
  name: 100,
  description: 200,
  largeText: 2000,
}

// AI Summary
export const AI_SUMMARY_LIMIT = 60
export const AI_SUMMARY_WARNING_LIMIT = 5 // Warn when only 5 uses left?
export const RESUME_SUMMARY_LIMIT_KEY = 'RESUME_SUMMARY'

export const NOT_FOUND_ROUTE = '/home/404'

export enum ViewModes {
  Swimlane = 'swimlane',
  Grid = 'grid',
}

export const MessagingSystemUserIds = Object.freeze({
  hiring_team: 'hiring_team',
})

export const MessagingSystemUsers: Record<string, BaseParticipantInformation> = {
  [MessagingSystemUserIds.hiring_team]: { displayName: 'Hiring Team', profilePicture: null, userId: MessagingSystemUserIds.hiring_team },
}

export const DEFAULT_CONVERSATION_CONFIG: IConversationTypeConfig = {
  canEditOwnMessages: true,
  canDeleteOwnMessages: false,
  canReactOnMessages: true,
  canDeleteConversation: false,
  canViewAllReadReceipts: false,
  canViewOwnReadReceipts: false,
}
