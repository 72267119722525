import { Component, EventEmitter, Input, Output } from '@angular/core'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'
import { IReference } from '../../model/interfaces'

@Component({
  selector: 'candidate-references',
  template: `<!-- Request Information -->
    <e11-empty-state
      *ngIf="references.length === 0 && userMode !== userModeEnum.Candidate"
      [icon]="'people_outline'"
      [iconSize]="'md-36'"
      containerClassOverrides="e11-mt-0"
      title="{{ 'No References' | translate }}"
      [subtitle]="
        openRequest ? 'Requested on ' + (requestTimestamp | date : 'MM/dd/yyyy - hh:mm a') : ('Candidate did not supply any References.' | translate)
      "
      [clickable]="false"
      [borderColor]="openRequest ? 'e11-border-skin-secondary' : ''"
      [showButton]="true"
      [buttonValue]="openRequest ? 'Pending Request' : 'Request References'"
      [buttonColor]="
        openRequest
          ? 'e11-border-skin-secondary e11-text-skin-secondary'
          : 'e11-border-skin-primary-accent hover:e11-bg-skin-primary-accent/20 e11-text-skin-primary-accent'
      "
      [buttonIcon]="openRequest ? 'pending' : ''"
      (buttonClicked)="!requestsDisabled && requestModal()"
      [disabled]="!!openRequest"
    >
    </e11-empty-state> `,
  styleUrls: ['./candidate-references.component.scss'],
})
export class CandidateReferencesComponent {
  reference?: IReference
  @Input() references: IReference[] = []
  @Input() openRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false
  @Input() userMode = UserMode.Candidate
  @Input() requestTimestamp?: string

  @Output() requestFromComponent = new EventEmitter()

  userModeEnum = UserMode

  constructor() {}
  requestModal() {
    this.requestFromComponent.emit('References')
  }

  displayReference(reference: IReference) {
    this.reference = reference
  }

  close() {
    this.reference = undefined
  }
}
