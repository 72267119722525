import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ISocialMedia, socialMediaFields } from '../../model/social-media.enum'
import { UserMode } from '../../model/component-modes.enum'
import { RequestedUpdateFields } from '../../model/candidate-application.model'

@Component({
  selector: 'view-social-media',
  template: `
    <e11-empty-state
      *ngIf="!loading && !hasSocialMedia"
      [icon]="'workspaces'"
      [iconSize]="'md-36'"
      containerClassOverrides="e11-mt-0"
      title="{{ 'No Social Media' | translate }}"
      [subtitle]="
        !readonlyMode ? ('Add social media links to your profile.' | translate) : ('You did not supply any social media links.' | translate)
      "
      [clickable]="false"
      [showButton]="!readonlyMode"
      [buttonValue]="'Add Social Media' | translate"
      [buttonColor]="'e11-border-skin-primary-accent hover:e11-bg-skin-primary-accent/20 e11-text-skin-primary-accent'"
      [buttonIcon]="'workspaces'"
      (buttonClicked)="!readonlyMode && editClicked.emit()"
    >
    </e11-empty-state>

    <div *ngIf="loading" class="e11-flex e11-my-10 e11-items-center e11-w-1/2 e11-gap-4 e11-flex-wrap md:e11-flex-nowrap">
      <div class="e11-skeleton-empty !e11-w-full"></div>
      <div class="e11-skeleton-empty !e11-w-full"></div>
    </div>

    <div
      *ngIf="!loading && hasSocialMedia"
      class="e11-grid e11-grid-cols-1 e11-items-center e11-justify-center md:e11-grid-cols-2 e11-gap-4 e11-mb-8"
    >
      <ng-container *ngFor="let field of socialMediaFields">
        <div class="e11-w-full md:e11-w-1/2 e11-flex e11-items-center e11-gap-2" *ngIf="_socialMedia?.[field.name]">
          <img src="assets/images/social/{{ field.logo }}.png" class="e11-w-[28px]" />
          <a target="_blank" class="e11-flex e11-items-center e11-font-medium e11-w-fit" [href]="_socialMedia?.[field.name]">
            <span>{{ field.label }}</span>
            <span class="material-icons material-icons-outlined e11-text-sm">open_in_new</span>
          </a>
        </div>
      </ng-container>
    </div>

    <ng-template *ngIf="showRequestButton">
      <ng-container *ngIf="userMode === userModeEnum.Employer">
        <div class="e11-w-full e11-text-center e11-my-4">
          <e11-button
            *ngIf="!openedRequest"
            color="primary-accent"
            [ghost]="true"
            value="{{ 'Request Social Media' | translate }}"
            (click)="!requestsDisabled && requestFromComponent.emit()"
            [disabled]="requestsDisabled"
          ></e11-button>
        </div>
        <!-- Pending/open request -->
        <request-open *ngIf="openedRequest" [request]="openedRequest"></request-open>
      </ng-container>
    </ng-template>
  `,
  styles: [],
})
export class ViewSocialMediaComponent {
  _socialMedia: ISocialMedia | undefined
  hasSocialMedia = false

  @Input() set socialMedia(socialMedia: ISocialMedia | undefined) {
    this._socialMedia = socialMedia

    this.hasSocialMedia = socialMedia ? Object.values(socialMedia).some(value => !!value) : false
  }

  @Input() showRequestButton = true
  @Input() loading: boolean = false
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() readonlyMode: boolean = false
  @Input() openedRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false

  @Output() requestFromComponent = new EventEmitter()
  @Output() editClicked = new EventEmitter()

  socialMediaFields = socialMediaFields
  userModeEnum = UserMode
}
