import { Inject, Injectable } from '@angular/core'
import { E11ErrorHandlerService, ILogger, isE11Error, LOGGER_TOKEN } from '@engineering11/web-api-error'
import { Timestamp } from '@engineering11/types'
import { ComponentStore, tapResponse } from '@ngrx/component-store'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { from, Observable } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import { NotificationTranslateService } from '../../service/notification-translate.service'
import { FeedbackRequest, IFeedback } from './feedback.model'
import { FeedbackService } from './feedback.service'

export interface IFeedbackStore extends EntityState<Timestamp<IFeedback>> {
  submittingFeedback: boolean
  loadingFeedback: boolean
}

export const feedbackAdapter: EntityAdapter<Timestamp<IFeedback>> = createEntityAdapter<Timestamp<IFeedback>>({
  selectId: feedback => feedback.id,
})
const selectors = feedbackAdapter.getSelectors()

const initialState: IFeedbackStore = feedbackAdapter.getInitialState({
  submittingFeedback: false,
  loadingFeedback: false,
})

@Injectable({
  providedIn: 'root',
})
export class FeedbackStore extends ComponentStore<IFeedbackStore> {
  constructor(
    private feedbackService: FeedbackService,
    private errorHandler: E11ErrorHandlerService,
    private notificationService: NotificationTranslateService,
    @Inject(LOGGER_TOKEN) private logger: ILogger
  ) {
    super(initialState)
  }

  // SELECTORS
  readonly getState = this.select(s => s)

  // Feedback for feature MUST be fetched before this is called
  readonly feedbackExistsForFeature$ = (feature: string) => this.select(s => selectors.selectAll(s).some(feedback => feedback.feature === feature))
  readonly loadingFeedback$ = this.select(s => s.loadingFeedback)
  readonly submittingFeedback$ = this.select(s => s.submittingFeedback)

  // EFFECTS
  readonly onAdd = this.effect((request$: Observable<FeedbackRequest>) =>
    request$.pipe(
      switchMap(request => {
        this.setFeedbackSubmitting(true)
        return from(this.feedbackService.add(request)).pipe(
          tapResponse(
            feedback => {
              this.notificationService.popNotification({ type: 'success', message: 'Thank you for your feedback!', title: 'Feedback submitted' })
              this.onFeedbackAdded(feedback)
            },
            (error: Error) => {
              this.setFeedbackSubmitting(false)
              this.errorHandler.handleError(error)
            }
          )
        )
      })
    )
  )

  readonly onFetchForFeature = this.effect((feature$: Observable<string>) =>
    feature$.pipe(
      switchMap(feature => {
        this.setFeedbackLoading(true)
        return this.feedbackService.getUserFeedbackForFeature(feature).pipe(
          tapResponse(
            feedback => this.onFeedbackFetched(feedback),
            (error: Error) => {
              this.setFeedbackLoading(false)
              this.errorHandler.handleError(error)
            }
          )
        )
      })
    )
  )

  // UPDATERS
  readonly setFeedbackSubmitting = this.updater((state, submittingFeedback: boolean) => ({ ...state, submittingFeedback }))
  readonly setFeedbackLoading = this.updater((state, loadingFeedback: boolean) => ({ ...state, loadingFeedback }))

  readonly onFeedbackAdded = this.updater((state, feedback: Timestamp<IFeedback>) =>
    feedbackAdapter.addOne(feedback, { ...state, submittingFeedback: false })
  )

  readonly onFeedbackFetched = this.updater((state, feedback: Timestamp<IFeedback>[]) =>
    feedbackAdapter.addMany(feedback, { ...state, loadingFeedback: false })
  )
}
