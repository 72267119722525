<ng-container>
  <!-- Empty State / Clickable -->
  <div class="e11-w-full" *ngIf="!loading && _currentSkills.length === 0 && state !== 'edit'">
    <e11-empty-state
      [icon]="'construction'"
      [iconSize]="'md-36'"
      title="{{ 'No Skills Added' | translate }}"
      [clickable]="false"
      [border]="showEmptyStateBorder ? 'dashed' : ''"
      containerClassOverrides="e11-mt-0"
      subtitle="{{ 'Show them what you got! Add any skills to your profile.' | translate }}"
    >
    </e11-empty-state>

    <div class="e11-flex e11-w-full e11-justify-center e11-items-center e11-mt-3">
      <ng-container *ngTemplateOutlet="addSkillButton"></ng-container>
    </div>
  </div>

  <!-- Skills, display -->
  <div>
    <div *ngIf="_currentSkills.length || loading" class="e11-flex e11-flex-wrap e11-gap-2 e11-items-center">
      <div id="skill-tag-{{ i }}" *ngFor="let item of _currentSkills; let i = index">
        <ui-tag-candidate
          [item]="item"
          [type]="'skill'"
          [readOnly]="readOnly"
          [markAsMissingSkills]="markAsMissingSkills"
          (tagAction)="userTagAction($event, _currentSkills, i)"
        ></ui-tag-candidate>
      </div>
      <ng-container *ngIf="loading">
        <ui-tag-candidate *ngFor="let i of 8 | range" [item]="undefined" [type]="'skill'"></ui-tag-candidate>
      </ng-container>
      <ng-container>
        <ng-container *ngTemplateOutlet="addSkillButton"></ng-container>
      </ng-container>
    </div>
  </div>
</ng-container>

<ng-template #skillPopover>
  <div class="e11-px-4 e11-pt-4 e11-pb-0">
    <form class="e11-mb-8" action="" [formGroup]="formAddSkill" (ngSubmit)="onSubmitFormAddSkill(_currentSkills)" novalidate>
      <div class="e11-flex e11-flex-wrap e11-justify-center e11-gap-4 md:e11-justify-start">
        <div class="e11-w-full md:e11-w-fit">
          <e11-input
            [type]="'text'"
            name="name"
            id="input_nameSkill"
            label="{{ 'Name' | translate }}"
            [size]="'sm'"
            borderGlow="primary"
            [parentForm]="formAddSkill"
            formControlName="name"
            [required]="true"
            [maxLength]="100"
          >
          </e11-input>
          <div *ngIf="isMobile" class="e11-w-full e11-relative e11-mb-4">
            <e11-error-messages [controls]="f.name" [errorMessages]="errorMessages"></e11-error-messages>
          </div>
        </div>
        <div class="e11-w-full md:e11-w-fit">
          <e11-select
            name="select_level"
            id="select_level"
            label="{{ isMobile ? 'Level' : ('Experience Level' | translate) }}"
            [size]="'sm'"
            [options]="skillLevelList"
            formControlName="level"
          >
          </e11-select>
        </div>
        <div class="e11-flex e11-items-end">
          <e11-button value="{{ 'Cancel' | translate }}" color="neutral" [buttonGroup]="true" [ghost]="true" [size]="'sm'" (click)="closePopover()">
          </e11-button>
          <e11-button [value]="ctaTextSkill" [color]="'primary-accent'" [type]="'submit'" [size]="'sm'"></e11-button>
        </div>
      </div>
      <div class="e11-hidden md:e11-flex e11-w-full e11-relative">
        <e11-error-messages [controls]="f.name" [errorMessages]="errorMessages"></e11-error-messages>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #addSkillButton>
  <div id="add-skill-action">
    <div
      *ngIf="!loading && !readOnly && !markAsMissingSkills"
      class="e11-min-h-[3rem] e11-text-skin-primary-accent hover:e11-text-skin-primary hover:e11-bg-skin-primary-accent/20 e11-flex e11-items-center e11-justify-center e11-rounded e11-border e11-border-dashed e11-border-skin-primary-accent hover:e11-border-skin-primary e11-text-center e11-text-sm e11-px-3 e11-cursor-pointer"
      (click)="stateChange(''); openPopover('add-skill-action')"
    >
      <span class="material-icons-outlined e11-mr-1">add_circle</span> {{ 'Add Skill' | translate }}
    </div>
  </div>
</ng-template>
