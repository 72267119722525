import { Injectable } from '@angular/core'
import { BaseConversationConfigProvider, IConversationTypeConfig } from '@engineering11/messaging-web'
import { ConversationTypes, DEFAULT_CONVERSATION_CONFIG } from '../../constants'

@Injectable({ providedIn: 'root' })
export class SocialDmConfigProvider extends BaseConversationConfigProvider {
  conversationType = ConversationTypes.SocialDm
  private config: IConversationTypeConfig = {
    ...DEFAULT_CONVERSATION_CONFIG,
    canDeleteOwnMessages: true,
    canReactOnMessages: true,
  }

  getConfig(): IConversationTypeConfig {
    return this.config
  }

  setConfig(config?: Partial<IConversationTypeConfig>): void {
    this.config = { ...this.config, ...config }
  }
}
