import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { E11Logger } from '@engineering11/web-api-error'
import { IRequestedUpdate, RequestedUpdateFields } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'
import { IFirstImpression } from '../../model/interfaces'
import { Size } from '@engineering11/ui-lib/e11-ui-config'

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'single-first-impression-card',
  template: `
    <div class="first-impression e11-relative">
      <!-- Empties -->
      <!-- Candidate -->
      <e11-empty-state
        *ngIf="!firstImpression && userMode === userModeEnum.Candidate"
        [icon]="'smart_display'"
        [iconSize]="'md-36'"
        title="{{ 'No First Impression Video' | translate }}"
        subtitle="{{ 'You have not uploaded a first impression yet.' | translate }}"
        [routerLink]="['/home/videos']"
      >
      </e11-empty-state>

      <!-- Employer -->
      <e11-empty-state
        *ngIf="!firstImpression && userMode !== userModeEnum.Candidate"
        [icon]="'smart_display'"
        [iconSize]="'md-36'"
        title="{{ 'No First Impression Video' | translate }}"
        [subtitle]="
          openRequest ? 'Requested on ' + (requestTimestamp | date : 'MM/dd/yyyy - hh:mm a') : ('No First Impression video was supplied.' | translate)
        "
        [clickable]="false"
        [borderColor]="openRequest ? 'e11-border-skin-secondary' : ''"
        [showButton]="true"
        [buttonValue]="openRequest ? 'Pending Response' : 'Request F.I.V.'"
        [buttonColor]="
          openRequest
            ? 'e11-border-skin-secondary e11-text-skin-secondary'
            : 'e11-border-skin-primary-accent hover:e11-bg-skin-primary-accent/20 e11-text-skin-primary-accent'
        "
        [buttonIcon]="openRequest ? 'pending' : ''"
        (buttonClicked)="openRequestUpdate.emit(true)"
        [disabled]="!!openRequest"
      >
      </e11-empty-state>
    </div>

    <div *ngIf="firstImpression">
      <div
        class="image-card {{ size }} e11-relative e11-border e11-border-skin-app-borders e11-bg-skin-black"
        [ngStyle]="{ 
      backgroundImage: 'url(' + firstImpression.thumb?.signedURL + ')',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
     }"
      >
        <div
          class="e11-flex e11-w-full e11-items-center e11-justify-center e11-absolute e11-inset-0 e11-text-skin-white/50 hover:e11-text-skin-white"
          (click)="onClickFirstImpression()"
        >
          <span class="material-icons-outlined md-48"> play_circle </span>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./single-first-impression-card.component.scss'],
})
export class SingleFirstImpressionCardComponent implements OnInit {
  @Input() firstImpression?: IFirstImpression
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() size: Size | undefined
  @Input() readonly = false
  @Input() openRequest?: RequestedUpdateFields
  @Input() displayRequestUpdate = false
  @Input() showRequestButton = true
  @Input() requestTimestamp?: string
  pendingRequest?: boolean = false
  userModeEnum = UserMode

  @Output() openVideoPlayer: EventEmitter<IFirstImpression> = new EventEmitter<IFirstImpression>()
  @Output() openRequestUpdate: EventEmitter<boolean> = new EventEmitter<boolean>()

  constructor(private logger: E11Logger) {}

  ngOnInit(): void {}

  onClickFirstImpression() {
    this.logger.log('opening first impression', this.firstImpression)
    this.openVideoPlayer.emit(this.firstImpression)
  }
}
