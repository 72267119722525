import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ListMode, UserMode } from '../../model/component-modes.enum'
import { ISelectableEducation } from '../../model/interfaces'

@Component({
  selector: 'ui-education',
  template: `<!-- Empty State / Clickable -->
    <e11-empty-state
      *ngIf="!loading && educations.length === 0"
      [icon]="'school'"
      [iconSize]="'md-36'"
      containerClassOverrides="e11-mt-0"
      title="{{ 'No Educational Information' | translate }}"
      [subtitle]="
        userMode === userModeEnum.Candidate
          ? ('Add educational information to your profile.' | translate)
          : ('You did not supply any information.' | translate)
      "
      [clickable]="false"
      [showButton]="userMode === userModeEnum.Candidate"
      [buttonValue]="'Add Education' | translate"
      [buttonColor]="'e11-border-skin-primary-accent hover:e11-bg-skin-primary-accent/20 e11-text-skin-primary-accent'"
      [buttonIcon]="'school'"
      (buttonClicked)="addItem.emit()"
    >
    </e11-empty-state>

    <ng-container *ngIf="educations.length > 0 || loading">
      <ng-container *ngIf="listMode === listModeEnum.Vertical">
        <div class="e11-block">
          <ng-container *ngFor="let education of educations">
            <ui-education-line-item
              [userMode]="userMode"
              [education]="education"
              (viewDetail)="viewDetail.emit($event)"
              (editView)="editView.emit($event)"
              (deleteItem)="deleteItem.emit($event)"
            ></ui-education-line-item>
          </ng-container>

          <ng-container *ngIf="loading">
            <ui-education-line-item [userMode]="userMode" [education]="undefined"></ui-education-line-item>
          </ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="listMode === listModeEnum.Horizontal">
        <p class="e11-flex e11-items-center e11-text-skin-light">
          <span class="material-icons-outlined md-18 e11-text-skin-secondary e11-mr-1"> info </span
          >{{ 'Adding or removing entries here only affects this application.' | translate }}
        </p>
        <div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-2 lg:e11-grid-cols-3 xl:e11-grid-cols-4 e11-gap-4">
          <ng-container *ngFor="let education of educations">
            <ui-education-line-item-card
              [education]="education"
              (viewDetail)="viewDetail.emit($event)"
              (editView)="editView.emit($event)"
              [removable]="true"
              (deleteItem)="deleteItem.emit($event)"
            ></ui-education-line-item-card>
          </ng-container>
          <ng-container *ngIf="loading">
            <ui-skeleton-card *ngFor="let i of 3 | range"></ui-skeleton-card>
          </ng-container>
        </div>
      </ng-container>

      <!-- <ng-container *ngIf="listMode === listModeEnum.Vertical">
        <div class="row items-container">
          <div class="col-xs-12 nopadding">
            <e11-button *ngIf="selectable" [color]="'secondary'" (click)="selectAll()" value="{{ 'Select All' | translate }}" [buttonGroup]="true">
            </e11-button>
            <e11-button *ngIf="selectable" [color]="'neutral'" [ghost]="true" (click)="deselectAll()" value="{{ 'Deselect All' | translate }}">
            </e11-button>
  
            <div *ngFor="let education of educations; let i = index">
              <div class="e11-flex e11-relative e11-border-b e11-border-skin-app-borders e11-mb-4 e11-pb-4">
                <e11-input-checkbox
                  *ngIf="selectable"
                  [(ngModel)]="education.selected"
                  (change)="select(education)"
                  class="control e11-pt-[5px] e11-pr-4 e11-pb-0 e11-pl-0 e11-min-w-10"
                >
                </e11-input-checkbox>
                <ui-education-line-item
                  [education]="education"
                  (viewDetail)="viewDetail.emit($event)"
                  (editView)="editView.emit($event)"
                  [removable]="true"
                  (deleteItem)="deleteItem.emit($event)"
                ></ui-education-line-item>
              </div>
            </div>
          </div>
        </div>
      </ng-container> -->
    </ng-container> `,
  styleUrls: ['./ui-education.component.scss'],
})
export class UiEducationsComponent {
  listModeEnum = ListMode
  userModeEnum = UserMode

  @Input() listMode: ListMode = ListMode.Horizontal
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() educations: ISelectableEducation[] = []
  @Input() loading = false
  @Input() selectable = false

  @Output() viewDetail = new EventEmitter()
  @Output() editView = new EventEmitter()
  @Output() addItem = new EventEmitter()
  @Output() deleteItem = new EventEmitter<ISelectableEducation>()
  @Output() selectionChange: EventEmitter<ISelectableEducation[]> = new EventEmitter()

  select(education: ISelectableEducation) {
    this.selectionChange.emit(this.educations)
  }

  selectAll() {
    this.educations.forEach(education => (education.selected = true))
    this.selectionChange.emit(this.educations)
  }

  deselectAll() {
    this.educations.forEach(education => (education.selected = false))
    this.selectionChange.emit(this.educations)
  }
}
