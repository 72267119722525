import { Injectable } from '@angular/core'
import { CollectionRepository, FirestoreQueryBuilder } from '@engineering11/web-api-firebase'
import { map, Observable } from 'rxjs'
import { IFeedback } from './feedback.model'
import { Timestamp } from '@engineering11/types'

@Injectable({ providedIn: 'root' })
export class FeedbackRepository extends CollectionRepository<IFeedback> {
  protected COLLECTION = 'feedback/'

  feedbackExists(featureUser: Pick<IFeedback, 'feature' | 'userId'>): Observable<boolean> {
    return this.getUserFeedbackForFeature(featureUser).pipe(map(feedback => feedback.length > 0))
  }

  getUserFeedbackForFeature(featureUser: Pick<IFeedback, 'feature' | 'userId'>): Observable<Timestamp<IFeedback>[]> {
    const { feature, userId } = featureUser
    const query = new FirestoreQueryBuilder<IFeedback>().where('feature', '==', feature).where('userId', '==', userId).build()
    return this.query(query)
  }
}
