import { INewResumeThreshold } from '@candidate/app/models/candidate-resume.model'
import { groupBy, groupByKey } from '@engineering11/utility'
import { Timestamp } from '@engineering11/types'
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity'
import { ResumeActions, ResumeActionTypes } from './resume.actions'
import { ICandidateResumeVM } from '@candidate/app/models/candidate-resume.vm'

export interface State extends EntityState<Timestamp<ICandidateResumeVM>> {
  loadedGetAll: boolean
  loadedGetShareTokens: boolean
  loadingCreateShareToken: boolean
  loadingDeleteShareToken: boolean
  loadingSelection: boolean
  error: any | null
  selectedResumeId: string | null
  newResumeThreshold: INewResumeThreshold | null
  latestShareTokenId: string | null
}

export const adapter: EntityAdapter<Timestamp<ICandidateResumeVM>> = createEntityAdapter<Timestamp<ICandidateResumeVM>>({
  selectId: resume => resume.id,
})

export const initialState: State = adapter.getInitialState({
  loadedGetAll: false,
  loadingSelection: false,
  loadedGetShareTokens: false,
  loadingCreateShareToken: false,
  loadingDeleteShareToken: false,
  error: null,
  selectedResumeId: null,
  newResumeThreshold: null,
  latestShareTokenId: null,
})

export function reducer(state = initialState, action: ResumeActions): State {
  switch (action.type) {
    case ResumeActionTypes.getNewResumeThresholdSuccess:
      return { ...state, newResumeThreshold: action.payload }
    case ResumeActionTypes.createNewResume:
      return { ...state, loadingSelection: true, selectedResumeId: null }
    case ResumeActionTypes.getAllResumes:
      return { ...state }
    case ResumeActionTypes.getAllResumesSuccess:
      return adapter.setMany(action.payload, {
        ...state,
        loadedGetAll: true,
      })
    case ResumeActionTypes.selectResume:
      return { ...state, selectedResumeId: action.payload, loadingSelection: false }

    case ResumeActionTypes.resetResumeSelection:
      return { ...state, selectedResumeId: null }
    case ResumeActionTypes.updateResumeSuccess:
      return adapter.updateOne({ id: action.payload.id, changes: action.payload }, { ...state })
    case ResumeActionTypes.createNewResumeSuccess:
      return adapter.addOne(action.payload, { ...state })
    case ResumeActionTypes.deleteResumeSuccess:
      return adapter.removeOne(action.id, { ...state })
    case ResumeActionTypes.shareResume:
      return { ...state, loadingCreateShareToken: true }
    case ResumeActionTypes.shareResumeSuccess:
      const resumeToUpdate = state.entities[action.payload.resumeId]
      const currentTokens = resumeToUpdate?.shareTokens ?? []
      return adapter.updateOne(
        { id: action.payload.resumeId, changes: { shareTokens: [...currentTokens, action.payload] } },
        { ...state, loadingCreateShareToken: false, latestShareTokenId: action.payload.id }
      )
    case ResumeActionTypes.getAllResumeTokensSuccess:
      const groupedByResumeId = groupByKey(action.payload, 'resumeId')
      return adapter.updateMany(
        Object.keys(groupedByResumeId).map(resumeId => {
          return { id: resumeId, changes: { shareTokens: groupedByResumeId[resumeId] } }
        }),
        { ...state, loadedGetShareTokens: true }
      )
    case ResumeActionTypes.deactivateResumeToken:
    case ResumeActionTypes.deleteResumeToken:
      return { ...state, loadingDeleteShareToken: true }
    case ResumeActionTypes.deactivateResumeTokenSuccess:
    case ResumeActionTypes.deleteResumeTokenSuccess:
      const tokens = state.entities[action.payload.resumeId]?.shareTokens ?? []
      return adapter.updateOne(
        { id: action.payload.resumeId, changes: { shareTokens: tokens.filter(token => token.id !== action.payload.id) } },
        { ...state, loadingDeleteShareToken: false }
      )
    case ResumeActionTypes.error:
      return { ...state, error: action.payload }
    default:
      return state
  }
}
