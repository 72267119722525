import { Injectable } from '@angular/core'
import { ICandidateResume, ICandidateResumeData, INewResumeThreshold } from '@candidate/app/models/candidate-resume.model'
import { environment } from '@candidate/environments/environment'
import { DateService } from '@engineering11/date-time'
import { IFileReceipt } from '@engineering11/files-web'
import { AtLeast } from '@engineering11/types'
import { compareDesc, isNotNil, omit, sortWith, valueOf } from '@engineering11/utility'
import { E11Logger } from '@engineering11/web-api-error'
import { Timestamp } from '@engineering11/types'
import { RestApiClient } from '@engineering11/web-api-rest'
import { Store } from '@ngrx/store'
import { Observable, firstValueFrom } from 'rxjs'
import { filter, map, take } from 'rxjs/operators'
import { getCurrentToken } from 'shared-lib'
import { CandidateResumeRepository } from './candidate-resume.repository'
import { ICandidateResumeVM } from '@candidate/app/models/candidate-resume.vm'

@Injectable({ providedIn: 'root' })
export class CandidateResumeService {
  private restApiClient: RestApiClient
  constructor(private resumeRepository: CandidateResumeRepository, private logger: E11Logger, private store: Store) {
    this.restApiClient = new RestApiClient({
      baseUrl: environment.services.candidate,
      token: store.select(getCurrentToken).pipe(filter(isNotNil)),
    })
  }

  getAllForUser(userId: string): Observable<Timestamp<ICandidateResume>[]> {
    const descByPrimary = compareDesc<Timestamp<ICandidateResume>>(valueOf('isPrimary')) // Primary first
    const descByDate = compareDesc<Timestamp<ICandidateResume>>(valueOf('__updatedAt')) // Recent activity first
    return this.resumeRepository.getAllForUser(userId).pipe(map(resumes => sortWith(resumes, [descByPrimary, descByDate])))
  }

  /**
   * Generates a new resume from the provided seed resume, or else bootstraps an entirely new one from user data
   * @param seedResume
   * @returns an observable of the resume that was created
   */
  create(seedResume?: ICandidateResume): Observable<Timestamp<ICandidateResume>> {
    const donorResume = seedResume ? omit<AtLeast<ICandidateResumeVM, 'id'>, 'shareTokens'>(seedResume, 'shareTokens') : undefined // do not actually save shareTokens
    return this.restApiClient.post<Timestamp<ICandidateResume>>('candidate-resume', { donorResume }).pipe(
      filter(isNotNil),
      map(res => {
        const resumeData = res.data
        // Deserialise dates until we have a nicer pattern
        const formattedResume = DateService.convertAllDateFields(resumeData) as Timestamp<ICandidateResume>
        return formattedResume
      }),
      take(1)
    )
  }

  update(resume: AtLeast<ICandidateResume, 'id'>) {
    const resumeToUpdate = omit<AtLeast<ICandidateResumeVM, 'id'>, 'shareTokens'>(resume, 'shareTokens') // do not actually save shareTokens
    return this.resumeRepository.update(resumeToUpdate)
  }

  delete(id: string) {
    this.logger.log('resumeService deleting', id)
    return this.resumeRepository.delete(id)
  }

  /**
   *  Last modified date helps the parser with work history.
   */
  parse(fileReceipt: IFileReceipt, lastModifiedDate?: number) {
    return this.restApiClient
      .post<Timestamp<ICandidateResumeData>>('candidate-resume/parse', { fileReceipt: fileReceipt, lastModifiedDate: lastModifiedDate })
      .pipe(
        filter(isNotNil),
        map(res => {
          const resumeData = res.data
          // Deserialise dates until we have a nicer pattern
          const formattedResume = DateService.convertAllDateFields(resumeData) as Timestamp<ICandidateResumeData>
          return formattedResume
        }),
        take(1)
      )
  }

  /**
   *  Last modified date helps the parser with work history.
   */
  parseAndSave(fileReceipt: IFileReceipt, lastModifiedDate?: number) {
    return this.restApiClient
      .post<Timestamp<ICandidateResume>>('candidate-resume/parse/save', { fileReceipt: fileReceipt, lastModifiedDate: lastModifiedDate })
      .pipe(
        filter(isNotNil),
        map(res => {
          const resumeData = res.data
          // Deserialise dates until we have a nicer pattern
          const formattedResume = DateService.convertAllDateFields(resumeData) as Timestamp<ICandidateResume>
          return formattedResume
        }),
        take(1)
      )
  }

  getNewResumeThreshold(userId: string): Observable<INewResumeThreshold> {
    return this.restApiClient.get<INewResumeThreshold>(`candidate-resume/${userId}/threshold`).pipe(
      filter(isNotNil),
      map(res => res.data)
    )
  }

  async generateResumeSummary(resumeId: string): Promise<string> {
    type ResumeSummaryResponse = { resumeSummary: string }

    // await sleep(2000) // For simulating loading and then an error
    // throw e11Error({ type: 'oops', title: 'Unexpected Error' })

    const resumeSummary$ = this.restApiClient.post<ResumeSummaryResponse>(`candidate-resume/${resumeId}/generate-summary`, {}).pipe(
      filter(isNotNil),
      map(res => res.data.resumeSummary.trim())
    )

    return firstValueFrom(resumeSummary$)
  }
}
