import { Component, EventEmitter, Input, Output } from '@angular/core'
import { ListMode, UserMode } from '../../model/component-modes.enum'

import { ISelectableWorkHistory } from './../../model/interfaces'

@Component({
  selector: 'ui-work-history',
  template: `
    <e11-empty-state
      *ngIf="workHistories.length === 0"
      [icon]="'work_outline'"
      [iconSize]="'md-36'"
      containerClassOverrides="e11-mt-0"
      title="{{ 'No Work History' | translate }}"
      [subtitle]="
        userMode === userModeEnum.Candidate
          ? ('Add some work experience to your profile.' | translate)
          : ('You did not supply any Work History.' | translate)
      "
      [clickable]="false"
      [showButton]="userMode === userModeEnum.Candidate"
      [buttonValue]="'Add Work Experience' | translate"
      [buttonColor]="'e11-border-skin-primary-accent hover:e11-bg-skin-primary-accent/20 e11-text-skin-primary-accent'"
      [buttonIcon]="'work_outline'"
      (buttonClicked)="addItem.emit()"
    >
    </e11-empty-state>

    <ng-container *ngIf="workHistories.length > 0 || loading">
      <ng-container *ngIf="listMode === listModeEnum.Horizontal">
        <p class="e11-flex e11-items-center e11-text-skin-light">
          <span class="material-icons-outlined md-18 e11-text-skin-secondary e11-mr-1"> info </span
          >{{ 'Adding and removing items here only affects this application.' | translate }}
        </p>
        <div class="e11-grid e11-grid-cols-1 md:e11-grid-cols-2 lg:e11-grid-cols-3 xl:e11-grid-cols-4 e11-gap-4">
          <ng-container *ngFor="let workHistory of workHistories">
            <ui-work-history-line-item-card
              [workHistory]="workHistory"
              (viewDetail)="viewDetail.emit($event)"
              (editView)="editView.emit($event)"
              [removable]="true"
              (deleteItem)="deleteItem.emit($event)"
            >
            </ui-work-history-line-item-card>
          </ng-container>
          <ng-container *ngIf="loading">
            <ui-skeleton-card *ngFor="let i of 3 | range"></ui-skeleton-card>
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="listMode === listModeEnum.Vertical">
        <div class="e11-block">
          <ng-container *ngFor="let workHistory of workHistories">
            <ui-work-history-line-item
              [userMode]="userMode"
              [removable]="true"
              [workHistory]="workHistory"
              (viewDetail)="viewDetail.emit($event)"
              (editView)="editView.emit($event)"
              (deleteItem)="deleteItem.emit($event)"
              [pdfMode]="pdfMode"
            >
            </ui-work-history-line-item>
          </ng-container>

          <ng-container *ngIf="loading">
            <ui-work-history-line-item *ngFor="let i of 3 | range" [userMode]="userMode" [workHistory]="undefined"> </ui-work-history-line-item>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  `,
  styleUrls: ['./ui-work-history.component.scss'],
})
export class UiWorkHistoryComponent {
  listModeEnum = ListMode
  userModeEnum = UserMode
  @Input() pdfMode = false

  @Input() listMode: ListMode = ListMode.Horizontal
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() workHistories: ISelectableWorkHistory[] = []
  @Input() loading = false
  @Input() selectable: boolean = false

  @Output() viewDetail = new EventEmitter<ISelectableWorkHistory>()
  @Output() editView = new EventEmitter()
  @Output() deleteItem = new EventEmitter<ISelectableWorkHistory>()
  @Output() selectionChange: EventEmitter<ISelectableWorkHistory[]> = new EventEmitter()
  @Output() addItem = new EventEmitter()

  select(workHistory: ISelectableWorkHistory) {
    this.selectionChange.emit(this.workHistories)
  }

  selectAll() {
    this.workHistories.forEach(workHistory => (workHistory.selected = true))
    this.selectionChange.emit(this.workHistories)
  }

  deselectAll() {
    this.workHistories.forEach(workHistory => (workHistory.selected = false))
    this.selectionChange.emit(this.workHistories)
  }
}
