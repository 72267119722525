import { Component, EventEmitter, Input, Output } from '@angular/core'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'
import { IPortfolio } from '../../model/portfolio.model'

@Component({
  selector: 'candidate-portfolio-request',
  template: ``,
})
export class CandidatePortfolioComponent {
  @Input() portfolio: IPortfolio[] | undefined
  @Input() openRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false
  @Input() userMode = UserMode.Candidate
  @Input() requestTimestamp?: string
  @Input() loading = false

  @Output() requestFromComponent = new EventEmitter()

  UserMode = UserMode
  constructor() {}

  requestModal() {
    this.requestFromComponent.emit('Portfolio')
  }
}
