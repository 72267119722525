import { Component, EventEmitter, Input, Output } from '@angular/core'
import { MilitaryAffiliation } from '../../model/enum'
import { UserMode } from '../../model/component-modes.enum'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { MilitaryAffiliationService } from '../../service/miltary-affiliation.service'

@Component({
  selector: 'view-military-affiliations',
  template: `
    <div *ngIf="!loading && !militaryAffiliations.length" class="e11-flex e11-flex-col e11-gap-4">
      <e11-empty-state
        [icon]="'military_tech'"
        [iconSize]="'md-36'"
        containerClassOverrides="e11-mt-0"
        title="{{ 'No Military Affiliations' | translate }}"
        [subtitle]="
          !readonlyMode
            ? ('Your service to your country is valued. Please feel free to share the branches of service you are affiliated with or served in.'
              | translate)
            : ('You did not supply any military affiliations.' | translate)
        "
        [clickable]="false"
        [showButton]="!readonlyMode"
        [buttonValue]="'Add Military Affiliations' | translate"
        [buttonColor]="'e11-border-skin-primary-accent hover:e11-bg-skin-primary-accent/20 e11-text-skin-primary-accent'"
        [buttonIcon]="'military_tech'"
        (buttonClicked)="!readonlyMode && editClicked.emit()"
      >
      </e11-empty-state>
    </div>

    <div *ngIf="loading" class="e11-flex e11-my-10 e11-items-center e11-w-1/2 e11-gap-4 e11-flex-wrap md:e11-flex-nowrap">
      <div *ngFor="let i of 3 | range" class="!e11-rounded-full e11-skeleton-empty !e11-w-[70px] !e11-h-[70px]"></div>
    </div>

    <div *ngIf="!loading && militaryAffiliations.length" class="e11-flex e11-items-center e11-gap-2 e11-flex-wrap">
      <img
        *ngFor="let militaryAffiliation of militaryAffiliations"
        [alt]="militaryAffiliation"
        [title]="militaryAffiliation"
        [src]="militaryAffiliationLogoMap.get(militaryAffiliation)"
        class="e11-w-[70px]"
      />
    </div>
  `,
  styles: [],
})
export class ViewMilitaryAffiliationsComponent {
  @Input() militaryAffiliations: MilitaryAffiliation[] = []

  @Input() showRequestButton = true
  @Input() loading: boolean = false
  @Input() userMode: UserMode = UserMode.Candidate
  @Input() readonlyMode: boolean = false
  @Input() openRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false
  @Input() requestTimestamp?: string

  @Output() requestFromComponent = new EventEmitter()
  @Output() editClicked = new EventEmitter()

  userModeEnum = UserMode
  militaryAffiliationLogoMap = this.militaryAffiliationService.affiliationUrlMap

  constructor(private militaryAffiliationService: MilitaryAffiliationService) {}
}
