import { Component, EventEmitter, Input, Output } from '@angular/core'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'
import { MilitaryAffiliation } from '../../model/enum'

@Component({
  selector: 'candidate-military',
  template: `<!-- Request Information -->
    <e11-empty-state
      *ngIf="!militaryAffiliations.length && userMode !== userModeEnum.Candidate"
      [icon]="'workspaces'"
      [iconSize]="'md-36'"
      containerClassOverrides="e11-mt-0"
      title="{{ 'No Military Affiliations' | translate }}"
      [subtitle]="
        openRequest
          ? 'Requested on ' + (requestTimestamp | date : 'MM/dd/yyyy - hh:mm a')
          : ('Candidate did not supply any military affiliations.' | translate)
      "
      [clickable]="false"
      [borderColor]="openRequest ? 'e11-border-skin-secondary' : ''"
      [showButton]="true"
      [buttonValue]="openRequest ? 'Pending Request' : 'Request Military Affiliations'"
      [buttonColor]="
        openRequest
          ? 'e11-border-skin-secondary e11-text-skin-secondary'
          : 'e11-border-skin-primary-accent hover:e11-bg-skin-primary-accent/20 e11-text-skin-primary-accent'
      "
      [buttonIcon]="openRequest ? 'pending' : ''"
      (buttonClicked)="!requestsDisabled && requestFromComponent.emit()"
      [disabled]="!!openRequest"
    >
    </e11-empty-state> `,
  styleUrls: [],
})
export class CandidateMilitaryComponent {
  @Input() militaryAffiliations: MilitaryAffiliation[] = []
  @Input() openRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false
  @Input() userMode = UserMode.Candidate
  @Input() requestTimestamp?: string

  @Output() requestFromComponent = new EventEmitter()

  userModeEnum = UserMode

  requestModal() {
    this.requestFromComponent.emit('MilitaryAffiliation')
  }
}
