import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core'
import { E11BackdropService } from '@engineering11/ui-lib/e11-backdrop'
import { Subscription } from 'rxjs'
import { RequestedUpdateFields } from '../../model/candidate-application.model'
import { UserMode } from '../../model/component-modes.enum'
import { IWorkHistory } from '../../model/interfaces'
import { ViewportService } from '@engineering11/web-utilities'

@Component({
  selector: 'candidate-work-history',
  template: `
    <e11-empty-state
      *ngIf="!loading && workHistory.length === 0 && userMode !== userModeEnum.Candidate"
      [icon]="'work_outline'"
      [iconSize]="'md-36'"
      containerClassOverrides="e11-mt-0"
      title="{{ 'No Work History' | translate }}"
      [subtitle]="
        openRequest
          ? 'Requested on ' + (requestTimestamp | date : 'MM/dd/yyyy - hh:mm a')
          : ('Candidate did not supply any Work History.' | translate)
      "
      [clickable]="false"
      [borderColor]="openRequest ? 'e11-border-skin-secondary' : ''"
      [showButton]="true"
      [buttonValue]="openRequest ? 'Pending Request' : 'Request Work History'"
      [buttonColor]="
        openRequest
          ? 'e11-border-skin-secondary e11-text-skin-secondary'
          : 'e11-border-skin-primary-accent hover:e11-bg-skin-primary-accent/20 e11-text-skin-primary-accent'
      "
      [buttonIcon]="openRequest ? 'pending' : ''"
      (buttonClicked)="!requestsDisabled && requestModal()"
      [disabled]="!!openRequest"
    >
    </e11-empty-state>
  `,
  styleUrls: ['./candidate-work-history.component.scss'],
})
export class CandidateWorkHistoryComponent implements OnInit, OnDestroy {
  workHistoryItem: IWorkHistory | undefined
  @Input() workHistory: IWorkHistory[] = []
  @Input() openRequest?: RequestedUpdateFields
  @Input() requestsDisabled = false
  @Input() userMode = UserMode.Candidate
  @Input() showRequestButton = true
  @Input() loading = false
  @Input() requestTimestamp?: string
  @Output() requestFromComponent = new EventEmitter()

  userModeEnum = UserMode

  isMobile: boolean = false
  viewportSubscription: Subscription

  constructor(private viewportService: ViewportService, private backdropService: E11BackdropService) {
    this.viewportSubscription = this.viewportService.viewportSizeChanged$.subscribe(data => {
      this.isMobile = data == 'sm' || data == 'xs'
    })
  }

  ngOnInit(): void {
    if (this.workHistory.length > 0) {
      this.workHistoryItem = this.workHistory[0]
    }
  }

  ngOnDestroy() {
    this.viewportSubscription.unsubscribe()
  }

  workHistoryViewDetail(item: IWorkHistory) {
    this.workHistoryItem = item
  }

  requestModal() {
    this.requestFromComponent.emit('Work History')
  }
}
